import './App.css';
import SimpleForm from './components/SimpleForm';

function App() {
  return (
    <div className="App">
      <SimpleForm />
    </div>
  );
}

export default App;
